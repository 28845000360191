import React from 'react'
import { PageProps } from 'gatsby'
import {
  Head,
  Hero,
  SectionTransform,
  ImageSection,
  MosaicSection,
  Enterprises,
  Footer,
  WhatsApp,
} from '~/components'
import { useMediaQuery } from 'react-responsive'
import { useApi } from '~/siteApi'
import styled from 'styled-components'

import Banner from '~/assets/img/Banner-home.jpg'
import BannerMobile from '~/assets/img/Banner-home-mobile.jpg'
import BalnearioCamboriu from '~/assets/img/Balneario-Camboriu.jpg'
import Itajai from '~/assets/img/Itajai.jpg'
import PraiaBrava from '~/assets/img/Praia-Brava.jpg'
import BeABroker from '~/assets/img/Seja-um-corretor.jpg'
import BCPraia from '~/assets/img/BC-Praia.jpg'

interface Context {
  enterprises: Array<never>
}

const SectionCities = styled.section`
  padding-top: 100px;

  @media (max-width: 1023px) {
    padding-top: 80px;
  }

  @media (max-width: 900px) {
    padding-top: 40px;
  }

  & > .titleEnterprises {
    line-height: 46px;
    font-size: 36px;
    font-weight: 300;
    color: #1d2241;
    width: 100%;
    padding: 0 60px;
    max-width: 1484px;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 1500px) {
      max-width: 1187px;
      padding: 0 48px;
    }

    @media (max-width: 1023px) {
      max-width: 760px;
      font-size: 16px;
      line-height: 26px;
    }

    @media (max-width: 900px) {
      margin-bottom: 20px;
    }
  }
`

const Home = ({ location, pageContext }: PageProps) => {
  const { enterprises }: Context = useApi(pageContext, 'page-home')

  const desktop = useMediaQuery({
    query: '(min-width: 501px)',
  })

  return (
    <>
      <Head location={location} />
      <Hero image={desktop ? Banner : BannerMobile} />
      <SectionTransform />
      <ImageSection src={BCPraia} />
      <SectionCities>
        <h2 className="titleEnterprises">
          Empreendimentos em Balneário Camboriú, Praia Brava e Itajaí
        </h2>
        <MosaicSection
          image={BalnearioCamboriu}
          title="Balneário Camboriú"
          description="Empreendimentos de alto padrão nas melhores regiões de Balneário Camboriú."
          buttonLabel="Conheça os empreendimentos"
          url="/empreendimentos/balneario-camboriu/"
          first
        />
        <MosaicSection
          image={PraiaBrava}
          title="Praia Brava"
          description="Empreendimentos de alto padrão nas melhores regiões da Praia Brava."
          buttonLabel="Conheça os empreendimentos"
          url="/empreendimentos/praia-brava/"
          reverse
        />
        <MosaicSection
          image={Itajai}
          title="Itajaí"
          description="Empreendimentos de alto padrão nas melhores regiões de Itajaí."
          buttonLabel="Conheça os empreendimentos"
          url="/empreendimentos/itajai/"
          last
        />
      </SectionCities>
      <Enterprises slider title="Empreendimentos" enterprises={enterprises} />
      <MosaicSection
        first
        last
        image={BeABroker}
        title="Seja um corretor"
        description="Faça seu cadastro e fique por dentro de todos os lançamentos da Aikon Empreendimentos."
        buttonLabel="Cadastre-se"
        url="/corretor/"
      />
      <Footer />
      <WhatsApp />
    </>
  )
}
export default Home
